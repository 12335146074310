import React from 'react';

import Layout from '../components/layout';
import CustomSlider from "../components/slider";

class ReferencjePage extends React.Component {

  render() {
    return (
      <Layout location={this.props.location.pathname}>
        <CustomSlider></CustomSlider>
      </Layout >
    )
  }
}

export default ReferencjePage